import { FC } from "react";

import PrimaryNavigation from "@components/PrimaryNavigation";
import SecondaryNavigation from "@components/SecondaryNavigation";

const LargeViewPortNavigation: FC = () => {
  return (
    <>
      <div className="LargeViewPortNavigation">
        <div className="secondary-wrapper">
          <SecondaryNavigation />
        </div>
        <PrimaryNavigation />
      </div>
      {/* language=CSS */}
      <style jsx>
        {`
          .LargeViewPortNavigation {
            display: none;
          }

          .secondary-wrapper {
            display: flex;
            justify-content: flex-end;
          }

          @media screen and (min-width: 768px) {
            .LargeViewPortNavigation {
              display: block;
            }
          }
        `}
      </style>
    </>
  );
};

export default LargeViewPortNavigation;
