import Customer from "@api/types/customer";
import Link from "next/link";
import { FC, useEffect, useRef, useState, MouseEvent } from "react";

import FaIcon from "@components/FaIcon";
import { IconColor, IconType } from "@components/FaIcon/FaIcon";

const CustomerPanel: FC<{ customer: Customer }> = ({ customer }) => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      return document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <>
      <div className="CustomerPanel">
        <span
          className="clickable"
          onClick={() => {
            setOpen(!open);
          }}>
          Hi {customer.firstName}{" "}
          <FaIcon type={open ? IconType.AngleUp : IconType.AngleDown} color={IconColor.Secondary} />
        </span>
        <div className="menu" ref={menuRef} onClick={(event) => {}}>
          <ul>
            <li>
              <Link href="/courses/purchased">
                <a
                  onClick={() => {
                    setOpen(false);
                  }}>
                  My Courses
                </a>
              </Link>
            </li>
            <li>
              <Link href="/gifts">
                <a
                  onClick={() => {
                    setOpen(false);
                  }}>
                  My Gifts
                </a>
              </Link>
            </li>
            <li>
              <Link href="/activate">
                <a
                  onClick={() => {
                    setOpen(false);
                  }}>
                  Activate License
                </a>
              </Link>
            </li>
            <li>
              <Link href="/order/history">
                <a
                  onClick={() => {
                    setOpen(false);
                  }}>
                  Order History
                </a>
              </Link>
            </li>
            <li>
              <Link href="/help-center">
                <a
                  onClick={() => {
                    setOpen(false);
                  }}>
                  Help Center
                </a>
              </Link>
            </li>
            <li>
              <Link href="/logout">
                <a
                  onClick={() => {
                    setOpen(false);
                  }}>
                  Logout
                </a>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* language=CSS */}
      <style jsx>
        {`
          .CustomerPanel {
            color: rgb(168, 182, 190);
            font-size: 12px;
            position: relative;
          }

          .clickable {
            cursor: pointer;
            user-select: none;
          }

          .menu {
            display: ${open ? "block" : "none"};
            position: absolute;
            right: 0;
            border-top: 1px solid rgb(102, 102, 102);
            background: rgb(243, 244, 244);
            padding: 0 10px;
            z-index: 1000;
          }

          .menu ul {
            list-style-type: none;
            padding: 0;
          }

          .menu li {
            white-space: nowrap;
            margin: 10px 0;
          }

          .menu a {
            font-size: 14px;
            color: rgb(168, 182, 190);
            text-decoration: none;
          }

          .menu a:hover {
            color: rgb(51, 51, 51);
          }
        `}
      </style>
    </>
  );
};

export default CustomerPanel;
