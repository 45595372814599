import { FC } from "react";

function getSpacerHeight(size: "sm" | "md" | "lg"): number {
  switch (size) {
    case "sm":
      return 16;
    case "md":
      return 40;
    case "lg":
      return 80;
  }
}

const Spacer: FC<{ size: "sm" | "md" | "lg" }> = ({ size }) => {
  return (
    <div className="spacer">
      {/* language=CSS */}
      <style jsx>
        {`
          .spacer {
            height: ${getSpacerHeight(size)}px;
          }
        `}
      </style>
    </div>
  );
};

export default Spacer;
