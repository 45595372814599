import { FC, PropsWithChildren } from "react";

const Container: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="container">
      {children}
      {/* language=CSS */}
      <style jsx>
        {`
          .container {
            padding: 0 20px;
          }

          @media (min-width: 1170px) {
            .container {
              width: 1170px;
              margin: 0 auto;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Container;
